<template>
  <div class="contract-detail">
    <CRow>
      <CCol md="9">
        <CCard>
          <CCardBody>
            <div class="text-info text-right pb-2" @click="gotoConfig">
              <span>{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_TITLE')}}</span>
            </div>

            <CCard>
              <CCardBody>
                <CForm>
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_PLATFORM')" horizontal :value="data.platform" />
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_NAME')" horizontal :value="data.name" />
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_SYMBOL')" horizontal :value="data.symbol" />
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_TOKEN_CONTRACT')" horizontal :value="data.tokenContract" />
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_LOCKING_CONTRACT')" horizontal :value="data.lockingContract" />
                  <CInput :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_EPY')" horizontal :value="data.epy" />
                  <CTextarea :label="$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_DISCRIPTION')" horizontal rows="3" />
                  <CRow>
                    <CCol md="3">
                      <h6 class="mt-1">{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_ACTIVE')}}</h6>
                    </CCol>
                    <CCol md="9">
                      <CSwitch
                        color="success"
                        :checked="data.active"
                        variant="3d"
                        v-bind="labelIcon"
                      />
                    </CCol>
                  </CRow>
                  <CRow class="mt-4 mb-2">
                    <CCol md="3">
                      <h6>{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_ICON')}}</h6>
                    </CCol>
                    <CCol md="9">
                      <div class="form-group file-area">
                        <label for="images"></label>
                        <input type="file" name="images" id="images" @change="uploadImage" />
                        <div class="file-dummy">
                          <img :src="previewImage" v-if="previewImage" />
                          <label v-else>{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_SELECT_IMAGE')}}</label>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <div class="text-center">
                    <CButton color="primary" class="pl-4 pr-4">{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_BUTTON_SAVE')}}</CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardBody>
                <div class="mb-2 text-right">
                  <CButton color="primary" @click="goDirect">
                    <span class="pr-1">
                      <CIcon :content="cilMedicalCross" />
                    </span>
                    {{$t('DETAIL_PLATFORM_CONTRACT_PAGE_LABEL_NEW_PLAN')}}
                  </CButton>
                </div>
                <CDataTable
                  class="mb-0"
                  outlined
                  striped
                  hover
                  :items="plans"
                  :fields="plansHeader"
                  head-color="light"
                  no-sorting
                >
                  <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1 }}</td>

                  <td class="text-center" slot="id" slot-scope="{ item }">
                    <span class="link-go-to" @click="goDirect(item)">
                      {{
                      item.id
                      }}
                    </span>
                  </td>
                  <td class="text-center" slot="duration" slot-scope="{ item }">{{ item.duration }}</td>
                  <td class="text-center" slot="epy" slot-scope="{ item }">{{ item.epy }}</td>

                  <td class="text-center" slot="status" slot-scope="{ item }">
                    <span v-if="item.active" class="active-text">{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_LABEL_ACTIVE')}}</span>
                    <span v-else class="inactive-text">{{$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_LABEL_INACTIVE')}}</span>
                  </td>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { cilMedicalCross } from "@coreui/icons";
export default {
  data() {
    return {
      cilMedicalCross,
      data: {
        platform: "ETH",
        name: "Infinito",
        symbol: "INFT",
        tokenContract: "0xABC...",
        lockingContract: "0xABC...",
        epy: "10%",
        lockupUnvote: "10",
        payloadReward: "10",
        active: true,
        address: "cosmos1dtq0y9reqst7d99fd3c7x6dflh4eazm4ha8qqh"
      },
      previewImage: null,
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715"
      },
      optionsPlatform: ["ETH - ERC20", "ETH - ERC21", "ETH - ERC22"],
      plansHeader: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "id", label:  this.$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_COL_PLAN_ID'), _classes: "text-center" },
        { key: "duration", label: this.$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_COL_DURATION'), _classes: "text-center" },
        { key: "epy", label: this.$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_COL_EARN_PER_YEAR'), _classes: "text-center" },
        { key: "status", label: this.$t('DETAIL_PLATFORM_CONTRACT_PAGE_TABLE_COL_STATUS'), _classes: "text-center" }
      ],
      plans: [
        {
          id: "ABC1",
          duration: "2 weeks",
          epy: "5%",
          active: true
        },
        {
          id: "ABC3",
          duration: "1 month",
          epy: "12%",
          active: false
        },
        {
          id: "ABC2",
          duration: "2 weeks",
          epy: "5%",
          active: true
        }
      ]
    };
  },
  methods: {
    uploadImage(event) {
      const image = event.target.files[0];
      console.log(image);
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },

    goDirect(item) {
      this.$router.push(`contract/edit-plan`);
    },

    gotoConfig() {
      this.$router.push("contract/payout-configuration");
    }
  }
};
</script>

<style lang="scss" scoped>
.contract-detail {
  $c-active: #388e3c;
  $c-inactive: #ff3d00;
  $c-link: #0000ff;
  .file-area {
    width: 100%;
    label {
      display: block;
    }
    input[type="file"] {
      position: absolute;
      width: 180px;
      height: 90%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      /*cursor: pointer;*/
    }

    .file-dummy {
      width: 160px;
      height: 160px;
      background: rgba(255, 255, 255, 0.2);
      transition: background 0.3s ease-in-out;
      label {
        text-align: center;
        padding-top: 70px;
        cursor: pointer;
      }
      img {
        width: 160px;
        height: 160px;
      }
    }

    input[type="file"]:valid + .file-dummy {
      background-color: #e5e5e5;
    }
  }

  .link-go-to {
    cursor: pointer;
    color: $c-link;
  }

  .active-text {
    color: $c-active;
  }
  .inactive-text {
    color: $c-inactive;
  }
}
</style>
